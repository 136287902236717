import axios from "axios";
import { useEffect, useState } from "react"; 
import { setDados, themeKalyst } from './theme'; 
 
import {  ChakraProvider,   Flex,Text,   extendBaseTheme, extendTheme} from '@chakra-ui/react'
import PageNotConfig from "../pages/painel/notFound/PageNotConfig";
export let website = null;
export let config  = null;
export let getWebsite = ()=>{  
    if(website.url == 'localhost'){
        return {
            // url:'snakepay.com.br',
            // url:'arkopay.com.br', 
            // url:'sypag.com',
            // url:'midaspag.com.br',
            // url:'fivepay.com.br',
            // url:'abcompagamentos.com',
            // url:'wegate.com.br',
            // url:'onepagamentos.com.br',
            // url:'titanshub.io',
            
            
            // url:'novakpay.com',
            
            // url:'openpay.app.br',
            // url:'dorapag.com',
            // url:'nacionalpag.com',
            // url:'v2tes3.kalyst.com.br',
            
            // url:'justpaypagamentos.com',
            // url:'propayoficial.com',
            // url:'fanaticpay.com',
            // url:'kalyst.com.br',
            // url:'armpay.com.br',
            // url:'wishpag.com',
            // url:'aionpay.com.br',
            // url:'loftpay.com.br', 
            // url:'alcateiapay.com',
            url:'guardpay.com.br',
            
            srv:'test'
        }
    }else{
        return website
    }
    
};
export default function PageConfig({children}){
    const [loadConfig,setLoadConfig]= useState(null);  
    useEffect(()=>{
      try {
        axios({  
            url: '/web/config.json?'+new Date().getTime(),
            responseType: 'json'
        }).then(function (response) {  
            try { 
                website= {
                    url :   window.location.hostname.replace('painel.','').replace('v2.','').replace('sandbox.',''),
                    srv :   (window.location.hostname.includes('painel.')|| window.location.hostname.includes('v2.')?'prod':'test') 
                }
                setDados(response.data[getWebsite().url]); 
                config = response.data[getWebsite().url]; 
                setLoadConfig(true);
            } catch (error) {
                setLoadConfig(false); 
            } 
        }).catch(()=>{
            setLoadConfig(false);
        });
      } catch (error) {
        
      } 
    })
    if(loadConfig== null){
        return null
    }else if(loadConfig){
        const theme = extendBaseTheme(themeKalyst);
        return  (
            <ChakraProvider theme={extendTheme(theme)}> 
                {children}
            </ChakraProvider>
        )  
    }else{
        return <ChakraProvider  > 
        <PageNotConfig/>
    </ChakraProvider>
    }
    
}